import React from 'react'
import { Link, useParams } from "react-router-dom";
// import './Css/style.css';
import Image from 'react-image-webp';
import Nav from '../home_page_v2/Nav/index';
import InputWithLabelByName from '../homePage/inputtypepaybyname';
import Sixth_Section from '../home_page_v2/Sixth_Section/index'

function CityGoogleBookAppointment({ isBookSlot, customCallus }) {
    const { city } = useParams();
    // const [searchParams, setSearchParams] = useSearchParams();
    // let phone = searchParams.get("phone")
    return (

        <>
        <Nav customCallus={customCallus} isBookSlot={!customCallus ? true : false} isPaidPage={!!true} />
            <section className="baner-region" style={{ height: "50vh"}}>

                <div className="container">
                    <><p>Day Consultation Charges - <b>Rs. 1000</b></p>
                        <p>Night consultation Charges - <b>Rs. 1500</b></p>
                        <p style={{ color: "#FB9F40" }}>Pay <b>Rs. 99</b> Now to Book Your Appointment</p>
                        <InputWithLabelByName city={city} />
                    </>
                    </div>

            </section>
            <Sixth_Section />
        </>

    )
}

export default CityGoogleBookAppointment
