import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Kyno Home Health Services: Revolutionizing Medical Home Care In Gurgaon</h3>
   <p>Suppose there is an emergency. What is the first resort? To call an ambulance. But the dealbreaker is when the ambulance is either unreachable or is taking too long to reach you. Time is so important in such a time. It would help if you took quick action to ensure the safety of the suffering person. Kyno Health is here to undo the wasted time that is spent trying to reach out for a quick-reaching ambulance. With us, you can get home visit doctors in Gurgaon. We will ensure medical safety right in the comfort of your home, bringing with us a load of quick options in emergencies. We are consistently working to transform the lives of hundreds of people by bringing them the help they deserve in times of need. With Kyno Health, a home visit can prove beneficial under the guidance of dedicated physicians, all of which is a call away. Our 24x7 availability brings us closer to helping you in times of need. Expect the expertise of the family doctor through our at-home medical services.</p>
 <Link className="btn btn-warning " to="tel:+919355618123">Call us: 93556 18123</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='Topology' />
    </div>
    <div className='tag'>
    <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    {/* <img src='images/tags-1.png' alt='tags' /> */}
    </div>

    <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    {/* <img src={bnrImg} alt='bnr-img' className='bnrimg img-fluid'/> */}
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
