import React from "react";
import Nav from "../Nav/index";
import Footer from "../Footer/index";

function index() {
  return (
    <>
      <Nav />
      <section>
        <div className="container">
          <h1 className="text-center mb-3">
            We're Just Around the Corner - 30+ Locations and Expanding!
          </h1>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16789.493890708953!2d77.043591!3d28.4582445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19f2db099e9f%3A0xdd47bf74c11f665a!2sDoctors%20Plaza!5e1!3m2!1sen!2sin!4v1725533954560!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Doctors Plaza, 78, Huda Market Rd,
                  Sector 15 Part 2, Sector 15, Gurugram, Haryana 122003
                </p>
                <a
                  href="https://maps.app.goo.gl/s724bcLR47ds3L4d7"
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16753.777809434512!2d77.1977353!3d28.6823109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd8a574957e3%3A0xf0570d31ed897d56!2sDoctors%20Plaza!5e1!3m2!1sen!2sin!4v1725533999887!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 24/156, 1st Floor, Doctors Plaza,
                  above Bank Of Baroda, opp. HP Petrol Pump, Block 25, Shakti
                  Nagar, New Delhi, Delhi, 110007
                </p>
                <a
                  href="https://maps.app.goo.gl/6k4XDggpwEGufVX17"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4190.554735334762!2d77.06925!3d28.629500000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM3JzQ2LjIiTiA3N8KwMDQnMDkuMyJF!5e1!3m2!1sen!2sin!4v1725534065320!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> A-96 SHANKAR GARDEN, VIKAS PURI
                </p>
                <a
                  href="https://maps.app.goo.gl/LAwX2icqrZHv1WR66"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16765.409504631065!2d77.3764878!3d28.6095154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce52620118ad3%3A0x3b77fc33cb873002!2sNoida%20Multispeciality%20Hospital!5e1!3m2!1sen!2sin!4v1725534096762!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> M-31 Main Road Mamura, near R.K
                  Public School, Shramik Kunj, Sector 66, Noida, Uttar Pradesh
                  201301
                </p>
                <a
                  href="https://maps.app.goo.gl/wA7mYBiSAzVy6NdG8"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16774.17283632209!2d77.226929!3d28.554559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3bb3df5e021%3A0x9d07957be5cf9784!2sSama%20Hospital!5e1!3m2!1sen!2sin!4v1725534128215!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Sama Hospital, 8, Siri Fort Rd,
                  Sector 3, Sadiq Nagar, New Delhi, Delhi 110049
                </p>
                <a
                  href="https://maps.app.goo.gl/kexagNe6hVLGmXG5A"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4195.581776236592!2d77.0332538!3d28.503335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d198c74b751bf%3A0xd09a36b23d163118!2snewlife%20Multispeciality%20Hospital%20and%20Trauma%20Centre%2C%20Palam%20Vihar%2C%20Gurgaon!5e1!3m2!1sen!2sin!4v1725534154504!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> G23M+88J, Dharam Colony, Palam Vihar
                  Extension, Gurugram, Haryana 122017
                </p>
                <a
                  href="https://maps.app.goo.gl/QxEqQjWrWPaqvypE7"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4653.334271908235!2d77.6737234!3d12.923820099999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae138e91d18e5f%3A0x3c2969401a383069!2sNELIVIGI%20MULTISPECIALITY%20AND%20UROLOGY%20HOSPITAL!5e1!3m2!1sen!2sin!4v1725534196243!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 449/434/09, Behind Kanti Sweets,
                  Bellandur Doddakannelli Road, Outer Ring Rd, Bellandur,
                  Bengaluru, Karnataka 560103
                </p>
                <a
                  href="https://maps.app.goo.gl/AsftVyF2Zr4Wzxby6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4653.336510140134!2d77.5739617!3d12.9237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3dd7901cc2f9%3A0x18b8a65ede823b57!2sSri%20SaiRam%20Hospital!5e1!3m2!1sen!2sin!4v1725534224497!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 729, 13th Main Rd, 7th Block,
                  Jayanagar, Bengaluru, Karnataka 560082
                </p>
                <a
                  href="https://maps.app.goo.gl/kaRQr3vEFPcNpk3a7"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4652.5287393194785!2d77.7171044!3d12.966972699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1223fc9dd1db%3A0x679620f7a86846b8!2sBrookefield%20Hospital%20-%20Your%20Friendly%20Neighbourhood%20Hospital!5e1!3m2!1sen!2sin!4v1725534251739!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Vijayalakshmi Square, 521/522, ITPL
                  Main Rd, Kundalahalli, Brookefield, Bengaluru, Karnataka
                  560037
                </p>
                <a
                  href="https://maps.app.goo.gl/xK7XVLFsxAtbBzyx9"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4652.342440427963!2d77.6363126!3d12.976932699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15ba453bafad%3A0x845de91fce2accf8!2sRevive%20Hospitals%20-%20Best%20Multispeciality%20Hospital%20in%20Indiranagar%20Bangalore!5e1!3m2!1sen!2sin!4v1725534280976!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 190, Paramahansa Yogananda Rd,
                  opposite Cauvery, Stage 2, Eshwara Layout, Indiranagar,
                  Bengaluru, Karnataka 560038
                </p>
                <a
                  href="https://maps.app.goo.gl/1WP3aroXMK9kygJb6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d18607.19577812548!2d77.5712967!3d13.0059465!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17546a4d2afd%3A0xbd831b0bc6fe7979!2sISHA%20DIAGNOSTICS%20AND%20RESEARCH%20PRIVATE%20LIMITED!5e1!3m2!1sen!2sin!4v1725534318396!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 311, between 15th and 16th cross,
                  Sampige Rd, Malleshwaram, Bengaluru, Karnataka 560003
                </p>
                <a
                  href="https://maps.app.goo.gl/Uohifknm42iDFecg6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16797.797255567937!2d77.0683079!3d28.4059211!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d22689d145c33%3A0xb70c9f69827b3!2sEmerald%20Plaza!5e1!3m2!1sen!2sin!4v1725535038454!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Gurgaon office 419-20, Emerald
                  Plaza, Sector 65, Gurgaon
                </p>
                <a
                  href="https://maps.app.goo.gl/wB1fCbDJST4wpYXF8"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d290.7833728285848!2d77.6066714!3d12.9666932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d544273efd%3A0xebbf793eefaded85!2s127%2C%20Brigade%20Rd%2C%20Shanthala%20Nagar%2C%20Ashok%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560025!5e1!3m2!1sen!2sin!4v1728378333645!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 127, Brigade Rd, Shanthala Nagar,
                  Ashok Nagar, Bengaluru, Karnataka 560025
                </p>
                <a
                  href="https://maps.app.goo.gl/wxHyuox2115boHh6A"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2326.831652615461!2d77.59074310000001!3d12.906152899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15115fb1b507%3A0xcc196edcc158f281!2sFirst%20Floor%2C%20291%2C%20Outer%20Ring%20Rd%2C%20Jeewan%20Griha%20Colony%2C%20J%20P%20Nagar%20Phase%205%2C%20J.%20P.%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560078!5e1!3m2!1sen!2sin!4v1728378397834!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 291, Outer Ring Rd, Jeewan Griha
                  Colony, J P Nagar Phase 5, J. P. Nagar, Bengaluru, Karnataka
                  560078
                </p>
                <a
                  href="https://maps.app.goo.gl/dFSTwCp73aPLCPPW6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d290.80035226030736!2d77.7001383!3d12.9521552!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1575d71a16af%3A0xbe2fd91fec750c64!2sSAAG%20Technologies%20Pvt%20Ltd!5e1!3m2!1sen!2sin!4v1728378445909!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 92/5 ACR Greens, III rd
                  Floor,Besides Biryani Zone, opp. Salarpuria, CKB Layout,
                  Chandra Layout, Marathahalli, Bengaluru, Karnataka 560037
                </p>
                <a
                  href="https://maps.app.goo.gl/QFkRkBgARRrxtYcL8"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4652.201099994679!2d77.7494146!3d12.9844841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0e033fe6522b%3A0x6df92666d58cee9a!2sTrinity%20Towers%2C%208%2C%20Whitefield%20Main%20Rd%2C%20near%20SDR%20Tower%2C%20Prasanth%20Extension%2C%20Whitefield%2C%20Bengaluru%2C%20Karnataka%20560066!5e1!3m2!1sen!2sin!4v1728378504458!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 8, Whitefield Main Rd, near SDR
                  Tower, Prasanth Extension, Whitefield, Bengaluru, Karnataka
                  560066
                </p>
                <a
                  href="https://maps.app.goo.gl/QFkRkBgARRrxtYcL8"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4651.269202201026!2d77.59903729999999!3d13.034165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17a28a2f7827%3A0x9c0ee4d8fd78d4f8!2s902-903%2C%204th%20Cross%20Rd%2C%20Amarjyothi%20Colony%2C%20Cholanayakanahalli%2C%20Hebbal%2C%20Bengaluru%2C%20Karnataka%20560032!5e1!3m2!1sen!2sin!4v1728378550270!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 902-903, 4th Cross Rd, Amarjyothi
                  Colony, Cholanayakanahalli, Hebbal, Bengaluru, Karnataka
                  560032
                </p>
                <a
                  href="https://maps.app.goo.gl/A9N1LdPtCMiXd86cA"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d581.8607338681353!2d77.6642322!3d12.8402988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6c8604879599%3A0xca6eb39e1575792!2sHpe%20Prathik%20Tech%20Park%2C%203rd%20Cross%20Rd%2C%20Electronics%20City%20Phase%201%2C%20Electronic%20City%2C%20Bengaluru%2C%20Karnataka%20560100!5e1!3m2!1sen!2sin!4v1728378594964!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 3rd Cross Rd, Electronics City Phase
                  1, Electronic City, Bengaluru, Karnataka 560100
                </p>
                <a
                  href="https://maps.app.goo.gl/WkgpcZ4EMEHtbFnx6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d290.78169641176027!2d77.5277566!3d12.9681277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3dbab1007aef%3A0x12f888c465e8fd88!2sNASIM%20KHAN%20HAIR%20SALOON!5e1!3m2!1sen!2sin!4v1728378636860!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 30, near Banglore One, Huchappa
                  Layout, Govindaraja Nagar Ward, Madhura Nagar, Vijayanagar,
                  Bengaluru, Karnataka 560072
                </p>
                <a
                  href="https://maps.app.goo.gl/8FYCeN8u9fxWgwt18"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d262.18309203064507!2d77.2155263!3d28.5197349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1ed613b0355%3A0xd4463cd0a64b69bf!2sSaket%20Caterer%20%26%20Decorator!5e1!3m2!1sen!2sin!4v1728378674579!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Shop No - 26, Central Market, J -
                  Block, Saket, New Delhi, Delhi 110017
                </p>
                <a
                  href="https://maps.app.goo.gl/8N8xrjJDSM63i9jW6"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2093.6453169343376!2d77.1205366!3d28.7111482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d01a1183aebbd%3A0xb04ad96d06eeb8a!2sShyamacreation!5e1!3m2!1sen!2sin!4v1728378714031!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 58/26, sec 31, near Bhagwan Mahavir
                  Marg, Pappu Colony, Rohini, Delhi, 110042
                </p>
                <a
                  href="https://maps.app.goo.gl/RnUbuYCDggXBw4i67"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2096.0538170739!2d77.0437187!3d28.5905809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ac4be82fa0b%3A0x2079024afea53411!2s1164%2C%20Pocket%207%20Rd%2C%20Pocket%207%2C%20Sector%2012%20Dwarka%2C%20Dwarka%2C%20Delhi%2C%20110075!5e1!3m2!1sen!2sin!4v1728378752775!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 1164, Pocket 7 Rd, Pocket 7, Sector
                  12 Dwarka, Dwarka, Delhi, 110075
                </p>
                <a
                  href="https://maps.app.goo.gl/pFD63J36PHxxb4KW9"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1047.7109606371819!2d77.1174141!3d28.622258!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d034efc861175%3A0xfb42ab7bb26df396!2sC%20108%2F109%2C%20Satguru%20Ram%20Singh%20Rd%2C%20Mayapuri%20Industrial%20Area%20Phase%20II%2C%20Mayapuri%2C%20New%20Delhi%2C%20Delhi%2C%20110064!5e1!3m2!1sen!2sin!4v1728378797099!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> C 108/109, Satguru Ram Singh Rd,
                  Mayapuri Industrial Area Phase II, Mayapuri, New Delhi, Delhi,
                  110064
                </p>
                <a
                  href="https://maps.app.goo.gl/yqcbE1haPQ9SMkWZ7"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16755.426430709467!2d77.2671231!3d28.6720035!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfc8a1444f9ff%3A0xf96046d170460a65!2sTirupati%20Agarbati!5e1!3m2!1sen!2sin!4v1728378840305!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> New Seelampur, Near Gurudwara, Shop
                  No. 7, H/150, Seelampur Colony, Seelampur, Delhi, 110031
                </p>
                <a
                  href="https://maps.app.goo.gl/gZc1giRkp1hLt4t7A"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d524.1503699939764!2d77.1788544!3d28.5631004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d9a71079465%3A0x6e3b9b1d761443a7!2sE180%2C%20Sector%204%2C%20Rama%20Krishna%20Puram%2C%20New%20Delhi%2C%20Delhi%20110022!5e1!3m2!1sen!2sin!4v1728378879568!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> E180, Sector 4, Rama Krishna Puram,
                  New Delhi, Delhi 110022
                </p>
                <a
                  href="https://maps.app.goo.gl/Agp5ztMksCkQDyM28"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d261.8897558096521!2d77.1806661!3d28.6374799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02bc84f9432d%3A0xb5ac1aee31698c68!2sE174%2C%20Block%20E%2C%20Rajender%20Nagar%20Part%202%2C%20New%20Rajinder%20Nagar%2C%20Delhi%2C%20110060!5e1!3m2!1sen!2sin!4v1728378921923!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> E174, Block E, Rajender Nagar Part
                  2, New Rajinder Nagar, Delhi, 110060
                </p>
                <a
                  href="https://maps.app.goo.gl/dJKqC1L8c6JWfbBv5"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2098.5121488787127!2d77.0742366!3d28.4670371!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1958dfacacd9%3A0x8bde58f5bed30d2f!2sACad%20Studio%20Pvt.%20Ltd.!5e1!3m2!1sen!2sin!4v1728378961989!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> 350 A, Block B, Sushant Lok Phase I,
                  Sector 43, Gurugram, Haryana 122001
                </p>
                <a
                  href="https://maps.app.goo.gl/P3ZN4izEkBi1mrhJ9"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16787.092843484686!2d77.5042715!3d28.4733582!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceb9e7f1ad88d%3A0x4bfe5aca3954c1c0!2sZoomeno&#39;d%20Pizza%20(KP-1%2C%20Gr%20Noida)!5e1!3m2!1sen!2sin!4v1728379004706!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Shop No. GF 6,7,8 OP PLAZA, KP-1,
                  near Kailash Hospital, Greater Noida, Uttar Pradesh 201308
                </p>
                <a
                  href="https://maps.app.goo.gl/fTmynpCJeRD6bNKZ9"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d524.1784506971578!2d77.3999134!3d28.5574613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cefcb10f0f7cf%3A0xa33405617eb9521c!2sMamb!5e1!3m2!1sen!2sin!4v1728379046424!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> C 15, Block B, Sector 80, Noida,
                  Uttar Pradesh 201305
                </p>
                <a
                  href="https://maps.app.goo.gl/ZTkqU9oHKXxGRX658"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16764.19278878551!2d77.4182018!3d28.617138!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef1a9f8d5f05%3A0xe8c510e3f7c5948a!2sMuthoot%20Finance%20Gold%20Loan!5e1!3m2!1sen!2sin!4v1728379092444!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Shop no-1, Shri balaji enclave, 14th
                  Ave, near radha Krishna mandir, opposite indian oil petrol
                  pump, Yusufpur, Gaur City 2, Greater Noida, Ghaziabad, Uttar
                  Pradesh 201305
                </p>
                <a
                  href="https://maps.app.goo.gl/vfhN5xbeMniGU2W1A"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9103.118031592277!2d77.06909275767592!3d28.405750101912073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23914bf1aff7%3A0x410005295574fb8f!2sKyno%20Health%20%7C%20Doctor%20At%20Home%20%26%20General%20Physician%20in%20Gurgaon!5e1!3m2!1sen!2sin!4v1731911977586!5m2!1sen!2sin" 
              style={{ width: "100%", height: "200px", border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> Golf Course Ext Rd, Sector 65, Gurugram, Haryana, 122101
                </p>
                <a
                  href="https://maps.app.goo.gl/xkjVEPr7rCzjxe1UA"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12">
              <div className="border border-success p-2 mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10633.70253312451!2d77.0692487!3d28.6295109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d05f3f317c5d5%3A0xe09dbaea4acaf1f7!2sKyno%20Health%20%7C%20Doctor%20At%20Home%20%26%20General%20Physician%20in%20Vikaspuri!5e1!3m2!1sen!2sin!4v1731912089676!5m2!1sen!2sin"
                  style={{ width: "100%", height: "200px", border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <p className="mt-3 map-address">
                  <strong>Address:</strong> A 96, Shankar Garden, Vikaspuri, Delhi, Delhi, 110018
                </p>
                <a
                  href="https://maps.app.goo.gl/geCB5UP19gVtrjW46"
                  className="btn btn-primary"
                  target="_blank"
                >
                  View Location
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default index;
