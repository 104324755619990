import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Experienced General Physician at home</h3>
    <h1>In 60 Minutes</h1>
    
    <p className='d-md-none d-lg-none'>Get a home visit from our experienced <br/>
& dedicated General Physicians who are <br/>
available 24X7.</p>

<p className='mob-noee'>Get a home visit by our experienced & dedicated General Physicians <br/> who are available 24X7. Kyno brings you the reliability of a 'family doctor'<br/>
 along with a wide range of at-home medical services.</p>
 <Link className="btn btn-warning " to="tel:+917996643888">Call us: 79966 43888</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='topology' />
    </div>
    <div className='tag'>
      <Image
      src={require('../../../images/tags-1.png')}
      webp={require('../../../images/tags-1.webp')}
      alt='tagsImg'
    />
    </div>
      <Image
      src={require('../../../images/bnr-img.png')}
      webp={require('../../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
