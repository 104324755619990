import React, { useEffect, useState } from 'react';
// import CustomTextField from './CustomTextField';
import { Button, TextField, Typography } from '@mui/material';
import PaymentStatusPopup from '../booking/PaymentStatusPopup';
import useRazorpay from 'react-razorpay';
import LabelComponent from '../booking/labelComponent';
import { useSearchParams } from 'react-router-dom';

const InputWithLabelByName = ({ city }) => {
    const [name, setName] = useState('');
    const [nameError, setError] = useState(false);
    const [slottype, setSlotType] = useState("Within 60 Mins")
    const [currentOrderId, setCurrentOrderId] = useState(null)
    const [openPopup, setOpenPopup] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [starttimeSelected, setStarttimeSelected] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    let phone = searchParams.get("phone")
    console.log({phone})
    // const name = "Google"
    // const [error, nameError] = useState(error);
    useEffect(() => {
        if (slottype === "Within 60 Mins") {
            setStarttimeSelected(new Date().toISOString())
        }
    }, [slottype])

    useEffect(() => {
        if (paymentStatus === "success") {
          window.location.href = '/booking/confirmation?bookedSlot=' + starttimeSelected;
      // navigate('/booking/confirmation?bookedSlot=' + starttimeSelected);
    }
      }, [paymentStatus])

    const createOrder = async (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "name": name,
            "mobile": phone,
            "slottype": slottype,
            "starttime": starttimeSelected,
            "city": city,
            "url": window.location.href
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        let response = await fetch(process.env.REACT_APP_NODE_BASE_URL + "/api/create_order", requestOptions)
        response = response.text()
        return response;
    };

    const [Razorpay, isLoaded] = useRazorpay();
    const handlePayment = async () => {
        if (!name) {
            nameError("Name is mandatory")
        }
        if (nameError || !name) {
            return
        }
        // if (newCityPage) {
        //     window.location.href = "/google-checkout" + "?" + phone;
        // }
        window.dataLayer.push({ 'event': 'initiate_checkout' });
        const { data: { grandTotal, orderId, secret } } = JSON.parse(await createOrder());
        // console.log({ order, name, phone })
        setPaymentStatus("pending")
        setCurrentOrderId(orderId)
        const options: RazorpayOptions = {
            key: secret,
            order_id: orderId,
            amount: grandTotal,
            currency: "INR",
            name: name,
            description: slottype,
            image: "https://www.kynohealth.com/images/Kyno-footer-logo.png",
            handler: (res) => {
                if (res.razorpay_payment_id) {
                    setPaymentStatus('pending');
                } else {
                    setPaymentStatus('failure');
                }
                console.log(res);
                setOpenPopup(true);
            },
            modal: {
                ondismiss: function () {
                    console.log("hello closing")
                    setPaymentStatus('failure');
                    setOpenPopup(true);
                },
            },
            prefill: {
                name: name,
                email: "aditya@kynohealth.com",
                contact: phone,
            },
            notes: {
                address: "HUAMI TECHNOLOGIES PRIVATE LIMITED, H NO M 37, THIRD FLOOR, MAYFIELD GARDEN, SECTOR 52, OPPOSITE ARTEMIS HOSPITAL, GURGAON, Gurgaon, Haryana, India, 122001",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    const handleValidation = (inputValue) => {
        // Simple phone number validation (only digits, length 10)
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!nameRegex.test(inputValue)) {
            setError('Invalid name. Only letters and spaces are allowed.');
        } else {
            setError('');
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        // Only allow digits (0-9) to be entered
        // if ((!(/^\d*$/.test(inputValue)) || inputValue.length > 10)) {
        //     setName(removeLastCharacter(inputValue))
        //     return
        // }
        setName(inputValue)
        handleValidation(inputValue);
    };

    const removeLastCharacter = (str) => {
        return str.slice(0, -1);
    };

    return (
        <div style={{ marginBottom: "20px", width: "300px", gap: "10px" , display:"flex", justifyContent: "space-between", alignItems:"center", flexWrap: "wrap"}}>
            {/* <LabelComponent text={label} /> */}
            <TextField
                variant="outlined"
                fullWidth
                placeholder={"Patient Name"}
                value={name}
                onChange={handleChange}
                error={!!nameError}
                helperText={nameError}
                // onClick={() => handleInputClick()}
                InputProps={{
                    sx: {
                        backgroundColor: '#f7f7f7',
                        borderRadius: '10px',
                        width: "auto",
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: nameError ? 'red' : 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: nameError ? 'red' : 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: nameError ? 'red' : 'transparent',
                        },
                        '& .MuiInputBase-input': {
                            color: '#424242',
                            fontSize: '16px',
                        },
                    },
                }}
            />
            {/* {error && (
        <Typography
          variant="body2"
          sx={{ color: 'red', marginTop: '5px', marginLeft: '10px' }}
        >
          {error}
        </Typography>
      )} */}
            <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                        height: "46px",
                        width: "auto",
                        fontWeight: "600",
                        padding: "12px 16px 12px 16px",
                        gap: "8px",
                        borderRadius: "10px",
                        fontSize: "14px",
                        background: (nameError || !name) ? "#dcdcdc" : "#FB9F40"
                    }}
                    disabled={(nameError || !name)}
                    onClick={() => handlePayment()}
                >
                    Confirm Booking
                </Button>
            <div style={{ textAlign: "center" }}>
            </div>
            <PaymentStatusPopup open={openPopup} onClose={() => setOpenPopup(false)} orderId={currentOrderId} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} />

        </div >
    );
};

export default InputWithLabelByName;
