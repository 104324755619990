import React from 'react'
import CountUp from 'react-countup';
import './Css/style.css'

function index() {
  return (
    <>
     <section className="second-fold">
     <div className="sepreat"></div>
     <div className="sepreat1"></div>
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='secnd-l-side'>
    <h5>OUR COMMUNITY</h5>
  {/* <img src='images/doctor.svg' className='img-fluid'/> */}
  </div>
  </div>
  
  
  {/* <div className="col-md-4 col-xs-12">
  <div className='secnd-r-side'>
  
    <h2>Our <span>NEW AGE<br/> HEALTHCARE</span> that <br/> keeps pace with<br/> you</h2>

    <img src='images/doctor.svg' className='img-fluid d-sm-none d-md-none d-lg-none'/>
    
  </div>
  </div> */}
  </div>  

  <div className='cont ml-70'>
    <div className='row'>
      <div className='col-md-3 col-6'>
      <div className="count-block">
<h4><span className="counter-count"><CountUp start={0} end={20000} delay={2} /><b>+</b></span></h4>
<p>Happy patients</p>
</div>
      </div>

      <div className='col-md-3 col-6'>
      <div className="count-block">
<h4><span className="counter-count"><CountUp start={0} end={25} delay={2} /><b>+</b></span></h4>
<p>Doctors</p>
</div>
      </div>

      <div className='col-md-3 col-6'>
      <div className="count-block">
<h4><span className="counter-count"><CountUp start={0} end={45} delay={2} /><b> mins</b></span></h4>
<p>Average Response time</p>
</div>
      </div>

      <div className='col-md-3 col-6'>
      <div className="count-block">
<h4><span className="counter-count"><CountUp start={0} end={5} delay={2}/><b>+</b></span></h4>
<p>Cities</p>
</div>
      </div>

    </div>
  </div>

  </div>
  </section>

 
    </>
  )
}

export default index