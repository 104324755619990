import React from 'react'
import { Link } from "react-router-dom";
import './Css/style.css';
import Image from 'react-image-webp';

function index() {
  return (

    <>
       <section className="baner-region">
	
  <div className="container">
  <div className="row">
  <div className="col-md-8 col-xs-12">
  <div className='left-side ml-70'>
    <h3>Kyno Health's Expert Medical Home Care Services: Experience Them Now In Bangalore</h3>
   <p>The hustle and bustle of urban life in Bangalore may sometimes keep you from accessing quality healthcare on time and visiting a clinic for regular testing. With Kyno Health's expert at-home medical care services, you never have to worry about missing another doctor's appointment. Kyno Health's mission is to bring the finest home visit doctors in Bangalore for your medical convenience. Our approach is no short of compassion, personalization, and comprehensive care. We are always working towards meeting the unique needs of each individual patient and attending to them in the comfort of their home.</p>
 <Link className="btn btn-warning " to="tel:+919355618123">Call us: 93556 18123</Link>

  </div>
  </div>
  
  
  <div className="col-md-4 col-xs-12">
  <div className='right-side'>
  <div className='aft'>
    <img src='/images/Topology-1.svg' alt='Topology' />
    </div>
    <div className='tag'>
    <Image
      src={require('../../images/tags-1.png')}
      webp={require('../../images/tags-1.webp')}
      alt='tagsImg'
    />
    {/* <img src='images/tags-1.png' alt='tags' /> */}
    </div>

    <Image
      src={require('../../images/bnr-img.png')}
      webp={require('../../images/bnr-img.webp')}
      className='bnrimg img-fluid'
      alt='bnrImg'
    />
    {/* <img src={bnrImg} alt='bnr-img' className='bnrimg img-fluid'/> */}
    
  </div>
  </div>
  </div>   		
  </div>
  </section>
    </>

  )
}

export default index
